import React, { createRef, FC, RefObject, useCallback, useEffect, useState } from 'react';
import TinyAccordion from 'react-tiny-accordion';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Typography from 'components/elements/Typography';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';
import IconCustom from 'components/helpers/IconCustom';

import { scrollIntoViewAccordion } from './helpers';
import { IAccordionItem, IPropsAccordion } from './models';

import {
  Accordion as Newaccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './Accordion.scss';

const OpenTabClassName = 'is-open';
const OpenAccordionClassName = 'open';
const AccordionParentClassName = 'accordion-parent';

const Accordion: FC<IPropsAccordion> = (props) => {
  const {
    accordion: { items, title, withouttinyaccordion  },
    productTitle,
    selectedIndex,
    onChange,
    changeOnClick,
    scrollTabIntoView,
    indicatorClassName,
    panelHeaderClassName,
  } = props;
  const { isMobile } = useScreenRecognition();
  const [tabsHeaderRef, setTabsHeaderRef] = useState<RefObject<HTMLDivElement>[] | []>([]);
  const [tabsContentRef, setTabsContentRef] = useState<RefObject<HTMLDivElement>[] | []>([]);

  const onChangeCallback = useCallback(
    (index: number, isOpen: boolean) => {
      if (scrollTabIntoView && isOpen) {
        scrollIntoViewAccordion({
          index,
          tabsHeaderRef,
          parentName: AccordionParentClassName,
          openName: OpenAccordionClassName,
          isMobile,
        });
      }

      if (onChange) {
        onChange(index);
        const accordionHeaderContainer = document.querySelectorAll('.accordion-parent .accordion__header');
        isOpen && accordionHeaderContainer[index] ? accordionHeaderContainer[index].setAttribute('aria-expanded', 'true') : accordionHeaderContainer[index].setAttribute('aria-expanded', 'false');
        accordionHeaderContainer.forEach((ele, i) => {
          i != index ? ele.setAttribute('aria-expanded', 'false') : '';
        })
        // @ts-ignore
        const regex = /(<([^>]+)>)/gi;
        const accordiontitle= items[index].title[0].text;
        const accordiontitlewithouttags = accordiontitle.replace(regex, " ");
        window.dataLayer?.push({
          'event': 'product_detail_interaction',
          'item_name' : `${productTitle}`,
          'click_text' : `${accordiontitlewithouttags}`
          });
      }
    },
    [tabsHeaderRef, scrollTabIntoView]
  );

  useEffect(() => {
    setTabsHeaderRef(items.map(() => createRef()));
    setTabsContentRef(items.map(() => createRef()));
  }, []);

  useEffect(() => {
    document.querySelectorAll('.accordion-parent .accordion__header')?.forEach((ele, i) => {
      i == 0 && ele ? ele.setAttribute('aria-expanded', 'true') : ele.setAttribute('aria-expanded', 'false');
    })
  }, []);

  return items?.length ? (
    <div data-testid="Accordion" className="accordion">
      {title ? <Typography data={title} customClass="accordion__title" /> : null}
      {withouttinyaccordion && withouttinyaccordion === '1' ? 
        <Newaccordion allowMultipleExpanded allowZeroExpanded >
          {items.map((item: IAccordionItem, i) =>
            item.content && item.title ? (
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <Typography
                      data={item.title}
                      customClass={classNames('accordion__header-text', {}, panelHeaderClassName)}
                    />
                    <IconCustom
                      icon="chevron-left-medium"
                      className={classNames('indicator', {}, indicatorClassName)}
                    />
                  </AccordionItemButton>
                </AccordionItemHeading>
              <AccordionItemPanel>
              {typeof item.content === 'string' ? (
                <DangerouslySetInnerHtml
                  refObj={tabsContentRef[i]}
                  className={classNames('accordion__content', {
                    [OpenTabClassName]: item.isOpened === '1',
                  })}
                  html={item.content}
                />
                ) : (
                  <div
                    ref={tabsContentRef[i]}
                    className={classNames('accordion__content', {
                      [OpenTabClassName]: item.isOpened === '1',
                    })}
                  >
                    {item.content}
                  </div>
                )}
              </AccordionItemPanel>
            </AccordionItem>                
          ) : null
        )}
      </Newaccordion> : (
      <TinyAccordion
        openClassName={OpenAccordionClassName}
        selectedIndex={selectedIndex}
        transitionDuration={scrollTabIntoView && isMobile ? 0 : 200}
        onChange={onChangeCallback}
        changeOnClick={changeOnClick}
        className={AccordionParentClassName}
      >
        {items.map((item: IAccordionItem, i) =>
          item.content && item.title ? (
            <div
              key={item.title[0].text}
              data-testid="header"
              data-header={
                <div ref={tabsHeaderRef[i]}>
                  <button
                    type="button"
                    className="accordion__header"
                    aria-label={item.title[0].typographyAriaLabel}
                  >
                    <Typography
                      data={item.title}
                      customClass={classNames('accordion__header-text', {}, panelHeaderClassName)}
                    />
                    <IconCustom
                      icon="chevron-left-medium"
                      className={classNames('indicator', {}, indicatorClassName)}
                    />
                  </button>
                </div>
              }
            >
              {typeof item.content === 'string' ? (
                <DangerouslySetInnerHtml
                  refObj={tabsContentRef[i]}
                  className={classNames('accordion__content', {
                    [OpenTabClassName]: item.isOpened === '1',
                  })}
                  html={item.content}
                />
              ) : (
                <div
                  ref={tabsContentRef[i]}
                  className={classNames('accordion__content', {
                    [OpenTabClassName]: item.isOpened === '1',
                  })}
                >
                  {item.content}
                </div>
              )}
            </div>
          ) : null
        )}
      </TinyAccordion>
    )}
    </div>
  ) : null;
};

export default Accordion;
